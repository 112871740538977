
// features/teamSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { calculatePlayerPositions } from "../utils/playerUtils";

export const updatePlayerInstructionAsync = createAsyncThunk(
  "team/updatePlayerInstruction",
  async ({ playerId, playerInstruction }, { getState }) => {
    const { teamSelection } = getState().team;
    const homePlayers = teamSelection.local?.team?.players || [];
    const homeSubstitutes = teamSelection.local?.team?.substitutes || [];

    const allPlayers = [...homePlayers, ...homeSubstitutes];

    const player = allPlayers.find((p) => p.id === playerId);

    if (player) {
      return { playerId, playerInstruction };
    } else {
      throw new Error("Player not found");
    }
  }
);

const initialState = {
  selectedSeason: null,
  selectedCompetition: null,
  loadingLocalTeam: false,
  loadingOpponent: false,
  errorLocal: false,
  errorOpponent: false,
  selectedTeamType: null,
  tactics: {
    defensiveStyles: [
      {
        id: 1,
        name: "Retrasado",
        description: "Esta táctica mantiene la formación y les ofrece a tus oponentes más posesión de balón. La delantera permanece pasiva, así que podrías tener menos opciones de ataque cuando recuperes el balón.",
      },
      {
        id: 2,
        name: "Equilibrado",
        description: "Un estilo equilibrado en el que tu equipo mueve el balón hacia el centro de la cancha y mantiene una formación neutral.",
      },
      {
        id: 3,
        name: "Presion al ataque",
        description: "El equipo mantendrá la formación hasta tener la oportunidad de presionar. Lo harán cuando el rival haga un toque fuerte, haya un balón suelto o deban controlar un pase difícil cerca de su arco.",
      },
      {
        id: 4,
        name: "Presion constante",
        description: "Esta táctica le pone mucha presión al equipo rival. Puede dejar a tu equipo vulnerable, dado que cansa más y hace más probable quedar fuera de posición.",
      },
    ],
    offensiveStyles: [
      {
        id: 1,
        name: "Balanceado",
        description: "Esta táctica se usa para un equipo equilibrado que mantiene la formación mientras arma una jugada de ataque. Los futbolistas darán apoyo y correrán cuando crean que es el momento indicado.",
      },
      {
        id: 2,
        name: "Armado lento",
        description: "El equipo dará más apoyo en el armado del ataque en lugar de realizar carreras de ataque. Esta táctica refuerza un estilo de juego con pases cortos, pero hará que el equipo tarde más en armarse.",
      },
      {
        id: 3,
        name: "Armado rapido",
        description: "Esta táctica anima al equipo a que se adelanten para armar rápido la jugada, pero si pierdes posesión del balón, te puedes exponer a un contraataque.",
      },
      {
        id: 4,
        name: "Pase largo",
        description: "El equipo correrá al espacio tras la línea defensiva rival o hacia un futbolista de referencia buscando pases largos, saltando la zona del mediocampo. Atacantes veloces y con buen posicionamiento ofensivo son mejores para esta táctica.",
      },
    ],
    playingStyles: [
      {
        id: 0,
        name: "Mi táctica",
        description: "Crea una instrucción opcional para el equipo:",
        defense: {
          styleId: 1,
          depth: 50,
        },
        attack: {
          styleId: 1,
          playersInBox: 3,
        },
      },
      {
        id: 1,
        name: "Estandar",
        description: "Este estilo tiene un enfoque equilibrado en cuanto al juego y hace hincapié en mantener la formación, lo cual ayudará a conservar la fortaleza defensiva y, a la vez, seguir siendo una amenaza en ataque.",
        defense: {
          styleId: 2,
          depth: 20,
        },
        attack: {
          styleId: 2,
          playersInBox: 2,
        },
      },
      {
        id: 2,
        name: "Por bandas",
        description: "Se aprovecha todo el ancho de la cancha cuando se está en posesión del balón y se intenta que el esférico llegue rápidamente a los atacantes cerca de las bandas, los laterales se suman al ataque por los costados para una mayor amenaza ofensiva.",
        defense: {
          styleId: 2,
          depth: 30,
        },
        attack: {
          styleId: 3,
          playersInBox: 3,
        },
      },
      {
        id: 3,
        name: "Posesión",
        description: "Estilo de juego basado en la posesión. Tus futbolistas están cómodos con el balón por toda la cancha y usan pases cortos y rotaciones. Al presentarse la oportunidad, los delanteros aprovechan los espacios en la defensa rival para atacar con éxito.",
        defense: {
          styleId: 3,
          depth: 40,
        },
        attack: {
          styleId: 2,
          playersInBox: 4,
        },
      },
      {
        id: 4,
        name: "Contraataque",
        description: "Este estilo no arriesga y defiende en la propia zona defensiva. Los futbolistas defienden el área y, al recuperar la posesión, hacen llegar el balón rápidamente a los atacantes para que corran al espacio detrás de la defensa rival.",
        defense: {
          styleId: 1,
          depth: 25,
        },
        attack: {
          styleId: 3,
          playersInBox: 5,
        },
      },
      {
        id: 5,
        name: "Presión Alta",
        description: "Estilo de juego basado en la fuerza y el atletismo que permite a tus futbolistas saltarse el mediocampo, permitiendo que el balón llegue rápido a tus potentes delanteros, quienes aprovechan el espacio a espalda de la defensa y disputan el balón.",
        defense: {
          styleId: 2,
          depth: 70,
        },
        attack: {
          styleId: 4,
          playersInBox: 5,
        },
      },
      {
        id: 6,
        name: "Todos atrás",
        description: "Tu equipo juega basado en una defensa sólida e implacable. Siempre disciplinado y bien organizado sin el balón, suelen jugar con una defensa atrasada que ahoga los ataques rivales antes de recuperar el balón para intentar construir desde ahí.",
        defense: {
          styleId: 1,
          depth: 5,
        },
        attack: {
          styleId: 1,
          playersInBox: 1,
        },
      },
    ],
    selectedGameStyleId: 0,
  },
  alertVisibility: {
    gameStyle: true,
    defensiveStyle: true,
    offensiveStyle: true,
  },
  teamSelection: {
    local: { team: null, teamID: null },
    opponent: { team: null, teamID: null },
  },
  formations: [
    "4-4-2",
    "4-3-3",
    "3-3-4",
    "3-5-2",
    "5-3-2",
    "4-5-1",
    "4-2-3-1",
    "4-3-2-1",
    "4-1-4-1",
    "3-4-3",
    "4-3-1-2",
    "3-6-1",
    "4-2-4",
    "5-4-1",
    "4-4-1-1",
    "3-4-1-2",
    "3-4-2-1",
    "4-1-3-2",
  ],
  selectedCompetitionType: "leagues",
  selectedCompetitionId: null,
  loadingHome: false,
  loadingAway: false,
  error: null,
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setErrorLocal: (state, action) => {
      state.errorLocal = action.payload;
    },
    setErrorOpponent: (state, action) => {
      state.errorOpponent = action.payload;
    },
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    selectTeam: (state, action) => {
      const { teamType, teamData } = action.payload;

      if (teamData.players && teamData.players.length > 0) {
        const isHost = teamType === "local";
        try {
          const playerPositions = calculatePlayerPositions(
            teamData.players,
            teamData.formation,
            isHost
          );
          teamData.players = teamData.players.map((player) => ({
            ...player,
            vectorPosition: {
              ...player.vectorPosition,
              x: playerPositions[player.id]?.x || player.vectorPosition.x,
              y: playerPositions[player.id]?.y || player.vectorPosition.y,
              initialX:
                playerPositions[player.id]?.x || player.vectorPosition.initialX,
              initialY:
                playerPositions[player.id]?.y || player.vectorPosition.initialY,
            },
          }));
        } catch (error) {
          console.error("Error calculating player positions:", error);
        }
      }

      const captainId = teamData.teamRoles?.captain;
      if (captainId !== undefined) {
        teamData.players = teamData.players.map((player) => ({
          ...player,
          isCaptain: player.playerNumber === captainId,
        }));
        teamData.substitutes = teamData.substitutes.map((player) => ({
          ...player,
          isCaptain: player.playerNumber === captainId,
        }));
      }

      if (teamType === "local") {
        state.teamSelection.local.team = teamData;
        state.teamSelection.local.teamID = teamData.teamID;
      } else if (teamType === "opponent") {
        state.teamSelection.opponent.team = teamData;
        state.teamSelection.opponent.teamID = teamData.teamID;
      }
    },
    setLoadingLocalTeam: (state, action) => {
      state.loadingLocalTeam = action.payload;
    },
    setLoadingOpponent: (state, action) => {
      state.loadingOpponent = action.payload;
    },
    setAlertVisibility: (state, action) => {
      const { alertType, isVisible } = action.payload;
      state.alertVisibility[alertType] = isVisible;
    },
    selectGameStyle: (state, action) => {
      state.tactics.selectedGameStyleId = action.payload.id;
    },
    updateTacticDetails: (state, action) => {
      const myTacticIndex = state.tactics.playingStyles.findIndex(
        (style) => style.id === 0
      );
      if (myTacticIndex === -1) return;

      Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key];
        const field = key.split(".");
        if (field.length === 1) {
          state.tactics.playingStyles[myTacticIndex][field[0]] = value;
        } else {
          state.tactics.playingStyles[myTacticIndex][field[0]][field[1]] =
            value;
        }
      });
    },
    changeFormation: (state, action) => {
      const { teamType, newFormation } = action.payload;
      const team = state.teamSelection.local.team;
      if (team && team.players && team.players.length > 0) {
        team.formation = newFormation;
        try {
          const playerPositions = calculatePlayerPositions(
            team.players,
            newFormation,
            teamType === "local"
          );

          team.players = team.players.map((player) => ({
            ...player,
            vectorPosition: {
              ...player.vectorPosition,
              x: playerPositions[player.id]?.x || player.vectorPosition.x,
              y: playerPositions[player.id]?.y || player.vectorPosition.y,
              initialX:
                playerPositions[player.id]?.x || player.vectorPosition.initialX,
              initialY:
                playerPositions[player.id]?.y || player.vectorPosition.initialY,
            },
          }));
        } catch (error) {
          console.error("Error calculating player positions:", error);
        }
      } else {
        console.log("Team not found or no players for:", teamType);
      }
    },
    updatePlayerPosition: (state, action) => {
      const { playerId, newX, newY } = action.payload;
      const team = state.teamSelection.local?.team;
      if (!team) return;

      const player = team.players.find((p) => p.id === playerId);

      if (player) {
        const radius = 10;
        const centerX = player.vectorPosition.initialX;
        const centerY = player.vectorPosition.initialY;

        const distance = Math.sqrt(
          Math.pow(newX - centerX, 2) + Math.pow(newY - centerY, 2)
        );

        if (distance <= radius) {
          player.vectorPosition.x = newX;
          player.vectorPosition.y = newY;
        } else {
          const angle = Math.atan2(newY - centerY, newX - centerX);
          player.vectorPosition.x = centerX + radius * Math.cos(angle);
          player.vectorPosition.y = centerY + radius * Math.sin(angle);
        }
      }
    },
    setSubstitutes: (state, action) => {
      state.substitutes = action.payload;
    },
    setSelectedTeamType: (state, action) => {
      state.selectedTeamType = action.payload;
    },
    setCompetitionType: (state, action) => {
      state.selectedCompetitionType = action.payload;
    },
    swapPlayers: (state, action) => {
      const { playerOne, playerTwo } = action.payload;
      const team = state.teamSelection.local?.team;

      if (!team) return;

      const isPlayerOneSubstitute = team.substitutes.some(
        (sub) => sub.id === playerOne.player.id
      );
      const isPlayerTwoSubstitute = team.substitutes.some(
        (sub) => sub.id === playerTwo.player.id
      );

      const playerOneRef = isPlayerOneSubstitute ? team.substitutes : team.players;
      const playerTwoRef = isPlayerTwoSubstitute ? team.substitutes : team.players;

      const playerOneIndex = playerOneRef.findIndex(
        (player) => player.id === playerOne.player.id
      );
      const playerTwoIndex = playerTwoRef.findIndex(
        (player) => player.id === playerTwo.player.id
      );

      if (playerOneIndex !== -1 && playerTwoIndex !== -1) {
        const tempPlayer = { ...playerOneRef[playerOneIndex] };
        playerOneRef[playerOneIndex] = { ...playerTwoRef[playerTwoIndex] };
        playerTwoRef[playerTwoIndex] = tempPlayer;

        const tempPosition = playerOneRef[playerOneIndex].vectorPosition;
        playerOneRef[playerOneIndex].vectorPosition =
          playerTwoRef[playerTwoIndex].vectorPosition;
        playerTwoRef[playerTwoIndex].vectorPosition = tempPosition;
      }
    },
    setSelectedSeason: (state, action) => {
      state.selectedSeason = action.payload;
    },
    setSelectedCompetitionId: (state, action) => {
      state.selectedCompetitionId = action.payload;
    },
    setLoadingHome: (state, action) => {
      state.loadingHome = action.payload;
    },
    setLoadingAway: (state, action) => {
      state.loadingAway = action.payload;
    },
    resetSeasonAndCompetition(state) {
      state.selectedSeason = null;
      state.selectedCompetitionId = null;
    },
    updateCaptainInTeam(state, action) {
      const newCaptain = action.payload;
      const team = state.teamSelection.local.team;

      if (team) {
        team.players = team.players.map((player) => ({
          ...player,
          isCaptain: player.playerNumber === newCaptain.playerNumber,
        }));
        team.substitutes = team.substitutes.map((player) => ({
          ...player,
          isCaptain: player.playerNumber === newCaptain.playerNumber,
        }));
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updatePlayerInstructionAsync.fulfilled,
      (state, action) => {
        const { playerId, playerInstruction } = action.payload;
        const homePlayers = state.teamSelection.local?.team?.players || [];
        const homeSubstitutes =
          state.teamSelection.local?.team?.substitutes || [];

        const allPlayers = [...homePlayers, ...homeSubstitutes];
        const player = allPlayers.find((p) => p.id === playerId);

        if (player) {
          player.playerInstruction = playerInstruction;
        } else {
          console.error("Player not found in extraReducer", playerId);
        }
      }
    );
  },
});

export const {
  resetSeasonAndCompetition,
  setLoadingLocalTeam,
  setLoadingOpponent,
  setErrorLocal,
  setErrorOpponent,
  setMyTeamSelection,
  setOpponentTeamSelection,
  setTeams,
  setAlertVisibility,
  selectGameStyle,
  updateTacticDetails,
  changeFormation,
  updatePlayerPosition,
  setSubstitutes,
  setSelectedTeamType,
  setCompetitionType,
  swapPlayers,
  selectTeam,
  setSelectedSeason,
  setSelectedCompetitionId,
  setLoadingHome,
  setLoadingAway,
  updateCaptainInTeam,
} = teamSlice.actions;

export default teamSlice.reducer;
