
// idealTeamSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setJerseyConfiguration } from "./jerseySlice";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

const LEVEL_COLORS = {
  1: "#005ac2",
  2: "#009372",
  3: "#2ba128",
  4: "#F5A623",
};

const initialState = {
  myIdealTeam: {
    starters: [],
    substitutes: [],
    captainId: null,
    jersey: {},
  },
  totalPlayers: 0,
  progress: 0,
  customInstructions: {},
  positionFilters: {},
  loading: false,
  error: null,
  playersByLevel: {
    level1: [],
    level2: [],
    level3: [],
  },
};

const fetchPlayersData = async () => {
  const db = getFirestore();

  const [levelOnePlayersSnap, levelTwoPlayersSnap, levelThreePlayersSnap] =
    await Promise.all([
      getDocs(collection(db, "playersCards/levelOne/players")),
      getDocs(collection(db, "playersCards/levelTwo/players")),
      getDocs(collection(db, "playersCards/levelThree/players")),
    ]);

  const processPlayers = (snap, level) => {
    return snap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      cardColor: LEVEL_COLORS[level] || "#000",
    }));
  };

  const playersByLevel = {
    level1: processPlayers(levelOnePlayersSnap, 1),
    level2: processPlayers(levelTwoPlayersSnap, 2),
    level3: processPlayers(levelThreePlayersSnap, 3),
  };

  const allPlayersMap = [
    ...playersByLevel.level1,
    ...playersByLevel.level2,
    ...playersByLevel.level3,
  ].reduce((acc, player) => {
    acc[player.id] = player;
    return acc;
  }, {});

  return { playersByLevel, allPlayersMap };
};

const fetchUserIdealTeam = async (userId) => {
  const db = getFirestore();
  const userRef = doc(db, "users", userId);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    const userData = userDoc.data();
    return (
      userData.idealTeam || {
        starters: [],
        substitutes: [],
        captainId: null,
        jersey: {},
      }
    );
  } else {
    throw new Error("El usuario no tiene un equipo ideal guardado.");
  }
};

const fetchPlayersAndIdealTeam = createAsyncThunk(
  "idealTeam/fetchPlayersAndIdealTeam",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const userId = state.auth.currentUser?.uid;

      if (!userId) {
        throw new Error("El ID del usuario no está disponible.");
      }

      const [playersData, idealTeam] = await Promise.all([
        fetchPlayersData(),
        fetchUserIdealTeam(userId),
      ]);

      const { playersByLevel, allPlayersMap } = playersData;

      const populateTeam = (teamList) =>
        teamList
          .map(({ player, order }) => ({
            ...allPlayersMap[player],
            order,
          }))
          .filter(Boolean)
          .sort((a, b) => a.order - b.order);

      const populatedIdealTeam = {
        starters: populateTeam(idealTeam.starters),
        substitutes: populateTeam(idealTeam.substitutes),
        captainId: idealTeam.captainId,
        jersey: idealTeam.jersey,
      };

      const currentCombination =
        state.jersey.jerseyCombinations[state.jersey.currentCombinationIndex];
      if (
        idealTeam.jersey &&
        JSON.stringify(currentCombination) !== JSON.stringify(idealTeam.jersey)
      ) {
        dispatch(setJerseyConfiguration(idealTeam.jersey));
      }

      return {
        playersByLevel,
        myIdealTeam: populatedIdealTeam,
      };
    } catch (error) {
      console.error("Error al obtener jugadores o el equipo ideal:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const idealTeamSlice = createSlice({
  name: "idealTeam",
  initialState,
  reducers: {
    setPositionFilter: (state, action) => {
      const { level, position } = action.payload;
      state.positionFilters[level] = position ?? ALL_POSITIONS;
    },
    resetPositionFilter: (state, action) => {
      const { level } = action.payload;
      state.positionFilters[level] = ALL_POSITIONS;
    },
    addPlayer: (state, action) => {
      const newPlayer = action.payload;
      if (state.myIdealTeam.starters.length < 11) {
        const order = state.myIdealTeam.starters.length + 1;
        state.myIdealTeam.starters.push({ ...newPlayer, order });
      } else if (state.myIdealTeam.substitutes.length < 5) {
        state.myIdealTeam.substitutes.push(newPlayer);
      }
      state.totalPlayers += 1;
      state.progress = (state.totalPlayers / 16) * 100;
    },
    removePlayer: (state, action) => {
      const { list, index } = action.payload;
      if (list === "starters") {
        state.myIdealTeam.starters.splice(index, 1);
        state.myIdealTeam.starters = state.myIdealTeam.starters.map(
          (player, i) => ({
            ...player,
            order: i + 1,
          })
        );
      } else if (list === "substitutes") {
        state.myIdealTeam.substitutes.splice(index, 1);
      }
      state.totalPlayers -= 1;
      state.progress = (state.totalPlayers / 16) * 100;
    },
    movePlayer: (state, action) => {
      const { sourceIndex, destIndex, sourceList, destList } = action.payload;

      if (sourceIndex === undefined || destIndex === undefined) return;
      if (sourceIndex === destIndex && sourceList === destList) return;

      const sourceArray = state.myIdealTeam[sourceList];
      const destArray = state.myIdealTeam[destList];

      if (!sourceArray || !destArray || sourceArray.length === 0) return;

      const [movedPlayer] = sourceArray.splice(sourceIndex, 1);

      if (sourceList === "starters" && destList === "starters") {
        sourceArray.splice(destIndex, 0, movedPlayer);
        state.myIdealTeam.starters = sourceArray.map((player, i) => ({
          ...player,
          order: i + 1,
        }));
      } else {
        if (destIndex >= destArray.length) {
          destArray.push(movedPlayer);
        } else {
          destArray.splice(destIndex, 0, movedPlayer);
        }
      }
    },
    setSelectedPlayers: (state, action) => {
      state.selectedPlayers = action.payload;
    },
    selectSubstitute: (state, action) => {
      state.selectedSubstitute = action.payload;
    },
    setCaptain: (state, action) => {
      state.myIdealTeam.captainId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayersAndIdealTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlayersAndIdealTeam.fulfilled, (state, action) => {
        state.playersByLevel = action.payload.playersByLevel;
        state.myIdealTeam = action.payload.myIdealTeam;
        state.loading = false;
      })
      .addCase(fetchPlayersAndIdealTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const saveIdealTeam = createAsyncThunk(
  "idealTeam/saveIdealTeam",
  async (updatedPlayers, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { substitutes, captainId } = state.idealTeam.myIdealTeam;
      const { jerseyCombinations, currentCombinationIndex } = state.jersey;
      const currentCombination = jerseyCombinations[currentCombinationIndex];
      const userId = state.auth.currentUser?.uid;

      if (!userId) {
        throw new Error("El ID del usuario no está disponible.");
      }

      const idealTeam = {
        starters: updatedPlayers.map((player, index) => ({
          order: index + 1,
          player: player.id,
        })),
        substitutes: substitutes.map((player) => ({
          player: player.id,
        })),
        captainId,
        jersey: currentCombination,
      };

      const db = getFirestore();
      const userRef = doc(db, "users", userId);

      await updateDoc(userRef, {
        idealTeam: idealTeam,
      });

      console.log("Equipo ideal guardado correctamente:", idealTeam);
      return idealTeam;
    } catch (error) {
      console.error("Error al guardar el equipo ideal:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const ALL_POSITIONS = null;

export const {
  addPlayer,
  removePlayer,
  movePlayer,
  resetSelection,
  setSelectedPlayers,
  selectSubstitute,
  selectPlayer,
  selectPlayerForRole,
  clearSelectedPlayerForRole,
  setCustomInstruction,
  setCaptain,
  setPositionFilter,
  resetPositionFilter,
} = idealTeamSlice.actions;

export const getPositionFilter = (state, level) =>
  state.idealTeam.positionFilters[level] ?? ALL_POSITIONS;

export const selectPlayersByLevel = (state, level) => {
  const filter = getPositionFilter(state, level);
  const players = state.idealTeam.playersByLevel[`level${level}`] || [];
  return filter === ALL_POSITIONS
    ? players
    : players.filter((player) => player.position === filter);
};

export const selectPlayerCount = (state) => {
  const startersCount = state.idealTeam.myIdealTeam.starters.length;
  const substitutesCount = state.idealTeam.myIdealTeam.substitutes.length;
  return startersCount + substitutesCount;
};

export const selectIsComplete = (state) => selectPlayerCount(state) === 16;


export const selectLoading = (state) => state.idealTeam?.loading ?? false;

export const selectError = (state) => state.idealTeam?.error ?? null;

export const getSelectedPlayerForRole = (state) =>
  state.idealTeam.selectedPlayerForRole;

export { fetchPlayersAndIdealTeam };

export default idealTeamSlice.reducer;
