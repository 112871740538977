
// store.js

import { configureStore } from '@reduxjs/toolkit';
import teamReducer from './features/teamSlice';
import playersReducer from './features/playersSlice';
import modalReducer from './features/modalSlice';
import roleSlice from './features/rolesSlice';
import stepperReducer from './features/stepperSlice';
import authReducer from './features/authSlice';
import notificationReducer from './features/notificationSlice';
import jerseyReducer from './features/jerseySlice';
import idealTeamReducer from "./features/idealTeamSlice";

export const store = configureStore({
  reducer: {
    team: teamReducer,
    players: playersReducer,
    modal: modalReducer,
    roles: roleSlice,
    stepper: stepperReducer,
    auth: authReducer,
    notification: notificationReducer,
    jersey: jerseyReducer,
    idealTeam: idealTeamReducer,
  },
});

export default store;

