import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser, clearUser } from '../features/authSlice';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../firebaseConfig';
import LoadingSpinner from '@/components/common/LoadingSpinner';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        const userData = {
          uid: authUser.uid,
          email: authUser.email,
          displayName: authUser.displayName,
          photoURL: authUser.photoURL,
          emailVerified: authUser.emailVerified,
        };
        dispatch(setUser(userData));
        setCurrentUser(userData);

        const userDocRef = doc(db, "users", authUser.uid);
        const unsubscribeUserDoc = onSnapshot(userDocRef, (userDoc) => {
          if (userDoc.exists()) {
            const userDataWithFirestore = { ...userData, ...userDoc.data() };
            dispatch(setUser(userDataWithFirestore));
            setCurrentUser(userDataWithFirestore);
          }
        });

        setLoading(false);

        return () => {
          unsubscribeUserDoc();
        };
      } else {
        dispatch(clearUser());
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, auth]);

  return (
    <AuthContext.Provider value={{ loading, currentUser }}>
      {loading ? (
        <div className="flex items-center justify-center min-h-screen bg-gray-500">
          <LoadingSpinner />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
