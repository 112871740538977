import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rolesData: [
    { roleKey: "captain", role: "Capitán del equipo", player: null, playerNumber: null, icon: "captain" },
    { roleKey: "left_free_kick", role: "Tiro libre izquierdo", player: null, playerNumber: null, icon: "soccer_goal" },
    { roleKey: "right_free_kick", role: "Tiro libre derecho", player: null, playerNumber: null, icon: "soccer_goal" },
    { roleKey: "long_free_kick", role: "Tiro libre largo", player: null, playerNumber: null, icon: "soccer_goal" },
    { roleKey: "penalty_kick", role: "Tiro de penales", player: null, playerNumber: null, icon: "soccer_ball" },
    { roleKey: "left_corner", role: "Corner izquierdo", player: null, playerNumber: null, icon: "left_corner" },
    { roleKey: "right_corner", role: "Corner derecho", player: null, playerNumber: null, icon: "right_corner" },
  ],
  selectedRole: null,
  selectedPlayer: null,
};

const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    selectRole(state, action) {
      state.selectedRole = action.payload;
      if (state.selectedPlayer && state.selectedRole) {
        assignPlayerToSelectedRole(state);
      }
    },
    assignPlayerToRole(state, action) {
      state.selectedPlayer = action.payload.player;
      if (state.selectedPlayer && state.selectedRole) {
        assignPlayerToSelectedRole(state);
      }
    },
    updateTeamRoles(state, action) {
      const { teamRoles, players } = action.payload;
      state.rolesData.forEach(role => {
        const playerNumber = teamRoles[role.roleKey];
        if (playerNumber !== undefined) {
          const player = players.find(p => p.playerNumber === playerNumber);
          if (player) {
            role.player = player.playerName;
            role.playerNumber = playerNumber;
          } else {
            role.player = null;
            role.playerNumber = null;
          }
        }
      });
    },
    clearSelectedPlayer(state) {
      state.selectedPlayer = null;
    },
  },
});

function assignPlayerToSelectedRole(state) {
  const role = state.rolesData.find(role => role.roleKey === state.selectedRole);
  if (role) {
    role.player = state.selectedPlayer.playerName;
    role.playerNumber = state.selectedPlayer.playerNumber;
    state.selectedPlayer = null;
    state.selectedRole = null;
  }
}

export const { selectRole, assignPlayerToRole, updateTeamRoles, clearSelectedPlayer } = roleSlice.actions;
export default roleSlice.reducer;

export const updateRoles = (team) => (dispatch) => {
  if (team?.teamRoles && team?.players) {
    const teamRoles = team.teamRoles;
    const players = team.players;
    dispatch(updateTeamRoles({ teamRoles, players }));
  }
};
