// src/services/authService.js

import { auth, functions, db } from "../firebaseConfig";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const ensureUserDocument = async (uid, userData) => {
  const triggerUserUpdate = httpsCallable(functions, 'triggerUserUpdate');
  try {
    const result = await triggerUserUpdate({ uid, ...userData });
    console.log('User document ensured:', result.data);
  } catch (error) {
    console.error('Error during user document creation:', error.message);
  }
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;
  await ensureUserDocument(user.uid, { username: user.displayName.split(" ")[0], email: user.email });
  return user;
};

export const signInWithEmail = async (emailOrUsername, password) => {
  let email = emailOrUsername.includes('@') ? emailOrUsername : await getEmailByUsername(emailOrUsername);
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;
  return user;
};

export const registerWithEmail = async (email, password, username) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await updateProfile(user, { displayName: username });

    await ensureUserDocument(user.uid, { username, email });

    return user;
  } catch (error) {
    console.error('Error during user registration:', error.message);
    throw new Error(`Failed to register user: ${error.message}`);
  }
};

export const resetPassword = async (email) => {
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    throw new Error("Invalid email format");
  }
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error('Error during password reset:', error);
    throw new Error('Failed to reset password');
  }
};

export const getEmailByUsername = async (username) => {
  if (!username.match(/^[a-zA-Z0-9_]+$/)) {
    throw new Error("Invalid username format");
  }

  const usersRef = collection(db, "users");
  const q = query(usersRef, where("username", "==", username));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    return userDoc.data().email;
  } else {
    throw new Error("Username not found");
  }
};

export const checkUsername = async (username) => {
  try {
    const checkUsernameAvailability = httpsCallable(functions, 'checkUsernameAvailability');
    const result = await checkUsernameAvailability({ username });
    return result.data.available;
  } catch (error) {
    console.error('Error checking username availability:', error);
    throw new Error('Failed to check username availability');
  }
};
