
// authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  signInWithGoogle,
  signInWithEmail,
  registerWithEmail,
  resetPassword,
  checkUsername as checkUsernameService,
} from '../services/authService';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';

const auth = getAuth();
const functions = getFunctions();

export const checkUsername = createAsyncThunk(
  'auth/checkUsername',
  async (username) => {
    try {
      const available = await checkUsernameService(username);
      return available;
    } catch (error) {
      throw new Error(`Failed to check username: ${error.message}`);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null,
    error: null,
    usernameAvailability: {
      loading: false,
      available: null,
      error: null,
    },
  },
  reducers: {
    setUser(state, action) {
      state.currentUser = action.payload;
    },
    clearUser(state) {
      state.currentUser = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    updateAvatar(state, action) {
      if (state.currentUser) {
        state.currentUser.avatar = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUsername.pending, (state) => {
        state.usernameAvailability.loading = true;
        state.usernameAvailability.error = null;
      })
      .addCase(checkUsername.fulfilled, (state, action) => {
        state.usernameAvailability.loading = false;
        state.usernameAvailability.available = action.payload;
      })
      .addCase(checkUsername.rejected, (state, action) => {
        state.usernameAvailability.loading = false;
        state.usernameAvailability.error = action.error.message;
      });
  },
});

export const { setUser, clearUser, setError, clearError, updateAvatar } = authSlice.actions;

const fetchUserProfile = async (uid) => {
  try {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    return userDoc.exists() ? userDoc.data() : null;
  } catch (error) {
    throw new Error(`Failed to fetch user profile: ${error.message}`);
  }
};

export const loginWithGoogle = () => async (dispatch) => {
  try {
    const user = await signInWithGoogle();
    const userProfile = await fetchUserProfile(user.uid);
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      avatar: userProfile?.avatar || user.photoURL,
      points: userProfile?.points || 0,
      statistics: userProfile?.statistics || { lost: 0, played: 0, won: 0 },
      level: userProfile?.level || 1,
    };
    dispatch(setUser(userData));
    return userData;
  } catch (error) {
    dispatch(setError(error.message));
    throw error;
  }
};

export const loginWithEmail = (emailOrUsername, password) => async (dispatch) => {
  try {
    const user = await signInWithEmail(emailOrUsername, password);
    const userProfile = await fetchUserProfile(user.uid);
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      avatar: userProfile?.avatar || user.photoURL,
      points: userProfile?.points || 0,
      statistics: userProfile?.statistics || { lost: 0, played: 0, won: 0 },
      level: userProfile?.level || 1,
    };
    dispatch(setUser(userData));
    return userData;
  } catch (error) {
    dispatch(setError(error.message));
    throw error;
  }
};

export const registerUser = (email, password, username) => async (dispatch) => {
  try {
    const user = await registerWithEmail(email, password, username);

    const triggerUserUpdate = httpsCallable(functions, 'triggerUserUpdate');
    await triggerUserUpdate({ uid: user.uid, username, email });

    const userProfile = await fetchUserProfile(user.uid);
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: username,
      photoURL: userProfile.avatar,
      emailVerified: user.emailVerified,
      avatar: userProfile.avatar,
      points: userProfile.points,
      statistics: userProfile.statistics,
      level: userProfile.level || 1,
    };

    dispatch(setUser(userData));
    return userData;
  } catch (error) {
    dispatch(setError(error.message));
    throw error;
  }
};

export const resetPasswordAction = (email) => async (dispatch) => {
  try {
    await resetPassword(email);
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const updateProfileImage = createAsyncThunk(
  'auth/updateProfileImage',
  async ({ uid, imageUrl }, { dispatch }) => {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, { avatar: imageUrl });
    dispatch(updateAvatar(imageUrl));
    return imageUrl;
  }
);

export const logout = () => async (dispatch) => {
  try {
    await auth.signOut();
    dispatch(clearUser());
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const selectCurrentUser = (state) => state.auth.currentUser;
export const selectError = (state) => state.auth.error;
export const selectUsernameAvailability = (state) => state.auth.usernameAvailability;

export default authSlice.reducer;

export const listenToAuthChanges = () => (dispatch) => {
  return onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        const userProfile = await fetchUserProfile(user.uid);
        const userData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          avatar: userProfile?.avatar || user.photoURL,
          points: userProfile?.points || 0,
          statistics: userProfile?.statistics || { lost: 0, played: 0, won: 0 },
          level: userProfile?.level || 1,
        };
        dispatch(setUser(userData));
      } catch (error) {
        dispatch(setError(error.message));
      }
    } else {
      dispatch(clearUser());
    }
  });
};
