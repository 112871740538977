
// playersSlice.jsx

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { swapPlayers } from "./teamSlice";

const initialState = {
  selectedPlayers: [],
  selectedSubstitute: null,
  selectedPlayer: null,
  selectedPlayerForRole: null,
  customInstructions: {},
  playerInstructions: [
    {
      positions: ["POR"],
      commands: [
        {
          option: 1,
          value: "a",
          title: "Mantente en la línea",
          description:
            "Quédate cerca de la línea de gol durante el juego abierto.",
        },
        {
          option: 2,
          value: "b",
          title: "Despeje rápido",
          description:
            "Libera el balón rápidamente después de hacer una parada para iniciar un contraataque.",
        },
        {
          option: 3,
          value: "c",
          title: "Organiza la defensa",
          description:
            "Dirige y organiza la línea defensiva y a tus compañeros cercanos.",
        },
        {
          option: 4,
          value: "d",
          title: "Cierra ángulos",
          description:
            "Posiciónate para reducir los ángulos de tiro disponibles al atacante.",
        },
      ],
    },
    {
      positions: ["LD", "LI", "DFC", "CAD"],
      commands: [
        {
          option: 1,
          value: "a",
          title: "Mantener la línea",
          description:
            "Mantén la formación defensiva y evita descolgarte del resto de la defensa.",
        },
        {
          option: 2,
          value: "b",
          title: "Despejar el balón",
          description:
            "Despeja el balón de tu área de defensa con seguridad cuando esté bajo presión.",
        },
        {
          option: 3,
          value: "c",
          title: "Marcaje estrecho",
          description:
            "Mantén un marcaje estrecho sobre el delantero asignado, especialmente en el área.",
        },
        {
          option: 4,
          value: "d",
          title: "Cortar líneas de pase",
          description:
            "Posiciónate para interceptar o cortar líneas de pase hacia delanteros clave.",
        },
      ],
    },
    {
      positions: ["MCD", "MC", "MI", "MD", "MP", "MCO"],
      commands: [
        {
          option: 1,
          value: "a",
          title: "Apoya el ataque",
          description:
            "Mueve hacia adelante para ofrecer opciones de pase en el ataque.",
        },
        {
          option: 2,
          value: "b",
          title: "Cobertura defensiva",
          description:
            "Regresa rápidamente para apoyar la defensa cuando el equipo pierde el balón.",
        },
        {
          option: 3,
          value: "c",
          title: "Distribuye el juego",
          description:
            "Controla el medio campo con pases precisos y control del juego.",
        },
        {
          option: 4,
          value: "d",
          title: "Presionar al oponente",
          description:
            "Aplica presión en el mediocampo para recuperar el balón.",
        },
      ],
    },
    {
      positions: ["DC", "EI", "ED"],
      commands: [
        {
          option: 1,
          value: "a",
          title: "Haz desmarques",
          description:
            "Haz desmarques detrás de la defensa para crear oportunidades de gol.",
        },
        {
          option: 2,
          value: "b",
          title: "Mantén el balón",
          description:
            "Mantén la posesión del balón y espera apoyo si estás aislado.",
        },
        {
          option: 3,
          value: "c",
          title: "Dispara a portería",
          description:
            "Toma oportunidades de disparo siempre que estés en posición favorable.",
        },
        {
          option: 4,
          value: "d",
          title: "Presiona la defensa",
          description:
            "Presiona a los defensores cuando tienen el balón para forzar errores.",
        },
      ],
    },
  ],
};

export const handlePlayerSelection = createAsyncThunk(
  "players/handlePlayerSelection",
  async (
    { player, index, isSubstitute, forRoleAssignment = false },
    { dispatch, getState }
  ) => {
    const { team: { teamSelection }, players: { selectedPlayers } } = getState();
    const homeTeam = teamSelection.local.team;

    if (!homeTeam) {
      console.error("No home team found");
      return;
    }

    const allHomePlayers = [...homeTeam.players, ...homeTeam.substitutes];
    const selectedPlayer = allHomePlayers.find((p) => p.id === player.id);

    if (!selectedPlayer) {
      console.error("Selected player not found");
      return;
    }

    let newSelection = { player: selectedPlayer, index, isSubstitute };

    if (selectedPlayers.some((p) => p.player.id === player.id)) {
      dispatch(resetSelection());
      return;
    }

    if (forRoleAssignment) {
      dispatch(selectPlayerForRole(newSelection));
    } else {
      dispatch(selectPlayer(newSelection));
    }

    if (
      selectedPlayers.length === 1 &&
      selectedPlayers[0].player.id !== player.id
    ) {
      const firstSelectedPlayer = allHomePlayers.find(
        (p) => p.id === selectedPlayers[0].player.id
      );
      if (
        (selectedPlayer.role === "Goalkeeper" &&
          firstSelectedPlayer.role !== "Goalkeeper") ||
        (selectedPlayer.role !== "Goalkeeper" &&
          firstSelectedPlayer.role === "Goalkeeper")
      ) {
        dispatch(resetSelection());
        return;
      }
      dispatch(
        swapPlayers({ playerOne: selectedPlayers[0], playerTwo: newSelection })
      );
      dispatch(resetSelection());
    }
  }
);

export const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    setSelectedPlayers: (state, action) => {
      state.selectedPlayers = action.payload;
    },
    selectSubstitute: (state, action) => {
      state.selectedSubstitute = action.payload;
    },
    selectPlayer: (state, action) => {
      const { player, index, isSubstitute } = action.payload;
      state.selectedPlayers.push({ player, index, isSubstitute });
    },
    resetSelection: (state) => {
      state.selectedPlayers = [];
      state.selectedPlayerForRole = null;
    },
    selectPlayerForRole: (state, action) => {
      state.selectedPlayerForRole = action.payload;
    },
    clearSelectedPlayerForRole: (state) => {
      state.selectedPlayerForRole = null;
    },
    setCustomInstruction: (state, action) => {
      const { playerId, instruction } = action.payload;
      state.customInstructions[playerId] = instruction;
    },
  },
});

export const {
  resetSelection,
  setSelectedPlayers,
  selectSubstitute,
  selectPlayer,
  selectPlayerForRole,
  clearSelectedPlayerForRole,
  setCustomInstruction,
} = playersSlice.actions;

export const getSelectedPlayerForRole = (state) =>
  state.players.selectedPlayerForRole;

export default playersSlice.reducer;
