
// App.jsx

import React, { Suspense, lazy } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AuthProvider, useAuth } from './contexts/AuthProvider';
import { PlayerProvider } from './contexts/PlayerContext';
import ErrorBoundary from './utils/ErrorBoundary';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import ToastContainer from '@/components/Toast/ToastContainer';

const AuthContent = lazy(() => import('./components/layout/AuthContent'));

const App = () => (
  <ErrorBoundary>
    <AuthProvider>
      <DndProvider backend={HTML5Backend}>
        <PlayerProvider>
          <div className="flex flex-col min-h-screen dark:bg-darkbackgroundColor bg-backgroundColor">
            <Suspense fallback={<LoadingSpinner />}>
              <AuthContentWrapper />
            </Suspense>
            <ToastContainer />
          </div>
        </PlayerProvider>
      </DndProvider>
    </AuthProvider>
  </ErrorBoundary>
);

const AuthContentWrapper = () => {
  const { loading, currentUser } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <LoadingSpinner />
      </div>
    );
  }

  return <AuthContent currentUser={currentUser} />;
};

export default App;
