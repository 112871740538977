import React from "react";

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="flex items-center justify-center space-x-2">
      <div className="w-4 h-4 rounded-full bg-gray-300 dark:bg-gray-600 animate-pulse"></div>
      <div className="w-4 h-4 rounded-full bg-gray-300 dark:bg-green-400 animate-pulse"></div>
      <div className="w-4 h-4 rounded-full bg-gray-300 dark:bg-gray-600 animate-pulse"></div>
    </div>
  </div>
);

export default LoadingSpinner;
