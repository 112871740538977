import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { Provider } from 'react-redux';
import store from './store';
import { ConfigProvider } from './contexts/ConfigContext';
import { BrowserRouter as Router } from 'react-router-dom';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ConfigProvider>
      <Router>
        <App />
      </Router>
    </ConfigProvider>
  </Provider>
);
